import React from 'react'
import {Link} from 'gatsby'
import ReactTooltip from 'react-tooltip'

import PortableText from './portableText'
import Container from './container'

import Info from '../images/icons/info.svg'

import styles from './page.module.css'

const Page = ({title, subTitle, _rawBody, pageSidebar, servicePageGroups}) => {
  return (
    <article className={styles.root} >

      <Container>
        <div>
          <div className={styles.heroWrapper}>
            <div className={styles.pageTitleWrapper}>
              <h1 className={styles.pageTitle}>{title}</h1>
              <h4 className={styles.pagesubTitle}>{subTitle}</h4>
            </div>
          </div>

          <div className={styles.mainContent}>
            <div className={styles.productsOuterWrapper}>
              {servicePageGroups.map(special => (
                <div key={special._key}>
                <h4>{special?.title}</h4>
                  <div className={styles.productsWrapper}>
                    {special.serviceDetailTable.map(service => (
                      <div className={styles.productWrapper} key={service._key}>
                        <div className={styles.productTitle}><span>{service.title}</span></div>
                        <div className={styles.productPrice}><span>{service.price} {service.subTitle && (<a data-tip={service.subTitle}><ReactTooltip place='bottom' event='click' globalEventOff='click' clickable /><Info className={styles.info} /></a>)} </span> </div>
                      </div>
                    ))}
                  </div>

                </div>
              ))}
              <div>{_rawBody && <PortableText blocks={_rawBody} />}</div>
            </div>

            {pageSidebar.length ? (
              <div className={styles.contentSideBar} >
                <nav className={styles.sideBarNav}>
                  <ul>
                    { pageSidebar[0].links.map(link => (
                      <li key={link._key}>
                        {(link.siteLink.includes('https') || link.siteLink.includes('http')) ? (
                          <a href={link.siteLink} target='_blank' rel='noopener noreferrer'>
                          <span>{link.title}</span>
                          <span className={styles.subNavSubTitle}>{link.subTitle}</span>
                        </a>
                        ) : (
                          <Link to={link.siteLink}>
                          <span>{link.title}</span>
                          <span className={styles.subNavSubTitle}>{link.subTitle}</span>
                        </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            ) : (
              ''
            )}

          </div>

        </div>
      </Container>
    </article>

  )
}
export default Page
