import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Page from '../components/service'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityServicePage(slug: { current: { eq: $slug } }) {
      title
      subTitle
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth: 1900) {
            ...GatsbySanityImageFluid
          }
          fixed(width: 1900) {
            ...GatsbySanityImageFixed
          }
        }
      }
      servicePageGroups {
      _key
      title
      serviceDetailTable {
        _key
        title
        subTitle
        price
      }
      }
      pageSidebar {
      _id
      links {
        _key
        title
        subTitle
        siteLink
        }
        siteAddress {
          companyName
          street
          city
          state
          zip
          mapLink
          phone
        }
      siteHours {
        monHours
        tueHours
        wedHours
        thurHours
        friHours
        satHours
        sunHours
      }
      }
      seoSettings {
      title
      description
      }
    }
  }
`

const ServicePage = ({data: {sanityServicePage: page}}) => (
  <Layout mainImage={page.mainImage} title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} />
        )}
    <Page
      title={page.title}
      subTitle={page.subTitle}
      _rawBody={page._rawBody}
      mainImage={page.mainImage}
      servicePageGroups={page.servicePageGroups}
      pageSidebar={page.pageSidebar}
    />
  </Layout>
)

export default ServicePage
